import { render, staticRenderFns } from "./advisor.vue?vue&type=template&id=ca223a58&scoped=true&class=mr-3&"
import script from "./advisor.vue?vue&type=script&lang=js&"
export * from "./advisor.vue?vue&type=script&lang=js&"
import style0 from "./advisor.vue?vue&type=style&index=0&id=ca223a58&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca223a58",
  null
  
)

export default component.exports