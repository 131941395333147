<template class="mr-3">
  <div>
    <a-tabs
      type="card"
      default-active-key="teacher"
      @change="callbackTabChange"
      size="large"
      :animated="true"
    >
      <a-tab-pane key="teacher">
        <span slot="tab">
          <div class="font-size-16 mb-3 p-1">
            <i class="fa fa-briefcase"></i>
            {{ $t("menu.listeEnseignants") }}
          </div>
        </span>
        <div v-if="activeKey == 'teacher'">
          <teacher />
        </div>
      </a-tab-pane>
      <a-tab-pane key="advisor">
        <span slot="tab">
          <div class="font-size-16 mb-3 p-1">
            <i class="fa fa-graduation-cap"></i>
            {{ $t("enseignant.conseillersPedagogiques") }}
          </div>
        </span>
        <div v-if="activeKey == 'advisor'">
          <advisor />
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
/* eslint-disable */
import teacher from "./components/teacher.vue";
import advisor from "./components/advisor.vue";

export default {
  components: {
    teacher,
    advisor,
  },
  methods: {
    callbackTabChange(key) {
      this.activeKey = key;
    },
  },
  data() {
    return {
      activeKey: "teacher",
    };
  },
};
</script>
